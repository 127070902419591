import {
    AppBar, Toolbar, Typography, Box, IconButton, Button,
    Card, CardHeader, CardContent, List, ListItem
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import QrCode2Icon from '@mui/icons-material/QrCode2';

import axios from './utils/axios';


function App() {


    return (
         <Box sx={{ flexGrow: 1 }}>
              <AppBar position="static">
                  <Toolbar>
                      <IconButton
                          size="large"
                          edge="start"
                          color="inherit"
                          aria-label="menu"
                          sx={{ mr: 2 }}
                      >
                          <MenuIcon />
                      </IconButton>

                      <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>Квитки</Typography>

                  </Toolbar>
              </AppBar>

              <Card>
                  <CardHeader title="Інструкція з використання" />

                  <CardContent>
                      <List sx={{ listStyle: "decimal", pl: 4 }}>
                          <ListItem sx={{ display: "list-item" }}>
                              Натисніть кнопку "Сканувати"
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                              Наведіть камеру телефону на зображення QR коду квитка
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                              Якщо операція була успішна - ви побачите повідомлення "Успішно" з інформацієї про квиток
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                              Якщо квиток вже було скановано до цього моменту - ви побачите повідомлення з датою та часом сканування
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                              При виникненні інших помилок - ви побачите їх деталі у повідомлені
                          </ListItem>
                          <ListItem sx={{ display: "list-item" }}>
                              Історію про всі сканування квитків ви можете бачити у історії повідомлень даного боту
                          </ListItem>
                      </List>
                  </CardContent>
              </Card>

        </Box>
    );
}

export default App;
